import React from "react";

const ServiceContent = [
  {
    icon: "ti-ruler-pencil",
    no: "01",
    service: "DIRECTION TECHNIQUE",
    title: "Faire de votre Système d'Informations votre force!",
    desc: `L'outil informatique doit apporter de la valeur à votre organisation. 
    Je me tiens à votre disposition pour vous aider à faire les meilleurs choix possibles, 
    avec efficience et pragmatisme.`,
    animationDelay: "",
  },
  {
    icon: "ti-image",
    no: "02",
    service: "MANAGEMENT DE PROJETS",
    title: "Un management bienveillant pour une efficacité optimale.",
    desc: `Pas de réussite d'un projet informatique sans un management précis, au coeur de son équipe et à l'écoute du métier.`,
    animationDelay: "150",
  },
  {
    icon: "ti-stats-up",
    no: "03",
    service: "BPM",
    title: "Industrialisation des process",
    desc: `Un vrai plaisir à décortiquer tous les process de votre entreprise et initier un plan d'actions
    permettant d'industrialiser tous les process métier chronophages qui pénalisent votre entreprise.`,
    animationDelay: "300",
  },
  {
    icon: "ti-brush-alt",
    no: "04",
    service: "UX/UI",
    title: "Améliorer l'expérience utilisateur.",
    desc: `Proposer des parcours et interfaces utilisateur simples et efficaces permettant
    un bon taux de conversion des utilisateurs de vos applications web et mobiles.`,
    animationDelay: "",
  },
  {
    icon: "ti-world",
    no: "05",
    service: "DEVELOPPEMENT WEB FULLSTACK",
    title: "Développement de vos sites Web.",
    desc: `Création d'applications web 100% personnalisées : vitrine, applications métiers, e-commerce, ...`,
    animationDelay: "150",
  },
  {
    icon: "ti-mobile",
    no: "06",
    service: "DEVELOPPEMENT MOBILE",
    title: "Développement d'applications mobiles multi-plateformes.",
    desc: `Développement d'applications Android/IOS à partir de framework permettant d'optimiser le time-to-market, 
    à savoir minimiser le temps nécessaire entre l'idée et la mise à disposition de l'application sur les stores.`,
    animationDelay: "300",
  },
];

const ServiceTwoAnimation = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div
            className="col-sm-6 col-lg-4"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
          >
            <div className="feature-box-02">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
              <p>{val.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwoAnimation;
