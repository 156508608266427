import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/about-4.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Sébastien Vergnolle</h3>
            <h5>
              <span className="color-theme"> Directeur Technique</span> passionné d'informatique et
              basé à <span className="color-theme"> Pérols</span>, près de Montpellier <span style={{ fontSize: '0.8rem' }}>(FRANCE)</span>,
              capable de vous accompagner sur toutes les phases de vos projets informatiques.
            </h5>
            <p>
              Plus de 19 années de prestations informatiques pour accompagner mes clients sur différents types d'activités : 
              <span className="color-theme">
                Conseil, Conception, Management, Développement, R&D, Maintenance
              </span>, etc.
            </p>
           <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Âge</label>
                  <p>{new Date().getFullYear() - 1977} ans</p>
                </div>
                <div className="media">
                  <label>Expérience</label>
                  <p>{new Date().getFullYear() - 2005} années</p>
                </div>
                <div className="media">
                  <label>Residence</label>
                  <p>Pérols, Montpellier</p>
                </div>
                <div className="media">
                  <label>Disponibilité</label>
                  <p>Immédiate</p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
                <div className="media">
                  <label>Email</label>
                  <p>vergnollesebastien@icloud.com</p>
                </div>
                <div className="media">
                  <label>Téléphone</label>
                  <p>06 89 12 79 98</p>
                </div>
                <div className="media">
                  <label>Salariat?</label>
                  <p>ouvert à CDI hors ESN</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Disponible</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="CV_Sebastien_VERGNOLLE.pdf" download>
                Télécharger mon cv
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
